import React from 'react'
import dodo from '../../images/dodologonew.png'
import School3D from '../../images/school.png'
import BoyarLviv from '../../images/boyarlogo.png'
import pixlab from '../../images/pixlab.jpg'
import cartonic from '../../images/cartonic.jpg'
import evmar from '../../images/evmar.png'
import akvarodyna from '../../images/akvarodyna.png'
import delfin from '../../images/delfin.png'
import goncharenko from '../../images/goncharenko.png'
import kryla from '../../images/kryla.png'
import lvivopenlab from '../../images/lvivopenlab.png'
import nashvulyk from '../../images/nashvulyk.png'
import padiyun from '../../images/padiyun.png'
import school29 from '../../images/school29.png'
import ururu from '../../images/ururu.png'
import sciencemuseum from '../../images/sciencemuseum.png'





const Sponsors = () => {
    return (
        <section>
            <div className="container">
                <h2 className="res_title_m">ПАРТНЕРИ</h2>
                <div className="partners_grid">
                    
                    
                    <div className="card">
                        <a href="https://school3d.com.ua/"  rel="noopener noreferrer"
                  target="_blank" style={{padding: `0.6rem`}}>
                        <img alt="school3d" src={School3D} />
                        </a>
                    </div>

                    <div className="card">
                    <a href="https://boyar.lviv.ua/"  rel="noopener noreferrer"
                  target="_blank" style={{padding: `0.6rem`}}>
                          <img alt="boyarlviv" src={BoyarLviv} />
                        </a>
                    </div>
                    <div className="card">
                    <a href="#"  rel="noopener noreferrer"
                  target="_blank" style={{padding: `0.6rem`}}>
                          <img alt="pixlab" src={pixlab} />
                        </a>
                    </div>

                    <div className="card">
                    <a href="#"  rel="noopener noreferrer"
                  target="_blank" style={{padding: `0.6rem`}}>
                          <img alt="school29" src={school29} />
                        </a>
                    </div>

                    <div className="card">
                    <a href="#"  rel="noopener noreferrer"
                  target="_blank" style={{padding: `0.6rem`}}>
                          <img alt="" src={nashvulyk} />
                        </a>
                    </div>
                    <div className="card">
                    <a href="#"  rel="noopener noreferrer"
                  target="_blank" style={{padding: `0.6rem`}}>
                          <img alt="lvivopenlab" src={lvivopenlab} />
                        </a>
                    </div>

                    <div className="card">
                    <a href="#"  rel="noopener noreferrer"
                  target="_blank" style={{padding: `0.6rem`}}>
                          <img alt="" src={goncharenko} />
                        </a>
                    </div>
                    
                    
                    <div className="card">
                    <a href="#"  rel="noopener noreferrer"
                  target="_blank" style={{padding: `0.6rem`}}>
                          <img alt="" src={kryla} />
                        </a>
                    </div>

                    <div className="card">
                    <a href="#"  rel="noopener noreferrer"
                  target="_blank" style={{padding: `0.6rem`}}>
                          <img alt="" src={padiyun} />
                        </a>
                    </div>
                   

                </div>
            </div>
            <br/>
            <br/>
            <div className="container">
                <h2 className="res_title_m">ГЕНЕРАЛЬНИЙ СПОНСОР</h2>
                <div style={{margin: `0 auto`, width: `100%`, maxWidth: `360px`}}>
                    <a href="https://dodotoys.com.ua/"   rel="noopener noreferrer"
                  target="_blank" >
                    <img src={dodo} alt="dodotoys"/>
                    </a>
                </div>
            </div>
            <br></br>

            <div className="container">
                <h2 className="res_title_m">Спонсори</h2>
                <div className="partners_grid">
                  
                     <div className="card">
                    <a href="#"  rel="noopener noreferrer"
                  target="_blank" style={{padding: `0.6rem`}}>
                          <img alt="Музей Науки" src={sciencemuseum} />
                        </a>
                    </div>

                    <div className="card">
                    <a href="https://evmar.com.ua"  rel="noopener noreferrer"
                  target="_blank" style={{padding: `0.6rem`}}>
                          <img alt="Avmar - магазин якісних товарів" src={evmar} />
                        </a>
                    </div>

                    <div className="card">
                    <a href="https://ururu.com.ua"  rel="noopener noreferrer"
                  target="_blank" style={{padding: `0.6rem`}}>
                          <img alt="Уруру" src={ururu} />
                        </a>
                    </div>

                    <div className="card">
                    <a href="#"  rel="noopener noreferrer"
                  target="_blank" style={{padding: `0.6rem`}}>
                          <img alt="Дельфін" src={delfin} />
                        </a>
                    </div>

                    <div className="card">
                    <a href="#"  rel="noopener noreferrer"
                  target="_blank" style={{padding: `0.6rem`}}>
                          <img alt="Аквародна" src={akvarodyna} />
                        </a>
                    </div>
                    </div>
            </div>
        </section>
    )
}

export default Sponsors
