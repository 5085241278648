import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Featured from "../components/sections/Featured"
import Contact from "../components/sections/Contact"
import Sponsors from "../components/sections/Sponsors"
//import ReactPlayer from "react-player"
import p2 from "../images/partenaires-detenantnew.png"
import imagesrc2 from "../images/sponsors-for-our-future.png"
import p11 from "../images/business.png"
import facebook from "../images/facebookicon.png"
import telegram from "../images/telegram-app.png"
import instagram from "../images/instagram.png"

const IndexPage = () => (
  <Layout>
    <Seo title="Puzzle Competition 2024 - Чемпіонат України з Пазлів" lang="uk" />
    <article>
      <Featured />
      {/*<div className="centered" style={{ margin: `30px auto` }}>
        <ReactPlayer
          url="https://www.youtube.com/embed/V6vW0jOVAps"
          playing={true}
    />
      </div> */}
      <div className="centered">
        <a
          href="https://fb.me/e/2jP4HdqXw"
          rel="noopener noreferrer"
          target="_blank"
          style={{
            textDecoration: `none`,
            display: `flex`,
            alignItems: `center`,
          }}
        >
          <img src={facebook} alt="facebook-event" width="80px" />
          <h3 style={{ display: `block`, marginLeft: `8px` }}>
            Подія у Facebook
          </h3>
        </a>
        
      </div>

      <div className="centered">
        <a
          href="https://instagram.com/puzzlecompetition.ua"
          rel="noopener noreferrer"
          target="_blank"
          style={{
            textDecoration: `none`,
            display: `flex`,
            alignItems: `center`,
          }}
        >
          <img src={instagram} alt="instagram" width="80px" />
          <h3 style={{ display: `block`, marginLeft: `8px` }}>
           @puzzlecompetition.ua
          </h3>
        </a>
      </div>


      <div className="centered">
        <a
          href="https://t.me/uapuzzle"
          rel="noopener noreferrer"
          target="_blank"
          style={{
            textDecoration: `none`,
            display: `flex`,
            alignItems: `center`,
          }}
        >
          <img src={telegram} alt="telegram" width="80px" />
          <h3 style={{ display: `block`, marginLeft: `8px` }}>
            Telegram канал
          </h3>
        </a>
      </div>



      



      <section style={{ padding: `45px 0px` }}>
        <div className="container" style={{ padding: `40px 20px` }}>
          <h2 className="uppercase res_title">Про чемпіонат 2024</h2>
          <h2>1-5 квітня - школи, садки<br/>6  квітня - індивідуальні<br/>13 квітня - фінал</h2>

          <div style={{ margin: `40px auto` }}>
            <h4 style={{ margin: `0 auto`, width: `100%`, maxWidth: `680px` }}>
            Цього року чемпіонат проходить в двох форматах: 
            <br/>
- індивідуальна реєстрація на локаціях партнерів чемпіонату
<br/>
- групова у школах та садках міста
<br/>
Реєструйтесь
            </h4>
            <br />
            <h4 style={{ margin: `0 auto`, width: `100%`, maxWidth: `680px` }}>
            Всі учасники отримають набір пазлів та сертифікат за участь, а фіналісти - гарні подарунки. 10 учасників, які покажуть найкращий час в кожній категорії, потраплять у фінал, що відбудеться 13 квітня.
            </h4>
          </div>

          <div
            style={{ margin: `30px auto`, width: `90%%`, maxWidth: `250px` }}
          >
            <img src={imagesrc2} alt="about" />
          </div>

          <h2>Вартість участі:</h2>
          <h2 style={{ textAlign: `center` }}>
            3-5 років - 150 грн
            <br />
            <br />
            6-8 років - 150 грн
            <br />
            <br />
            9-11 років - 200 грн
            <br />
            <br />
            12-14 років - 250 грн
            <br />
            <br />
            14+ років - 250 грн
            <br />
            <br />
           
            <br />
            <br />
           
          </h2>

          <h2 style={{ marginTop: `28px` }}>
            <a
              className="media_link"
              href="https://city-adm.lviv.ua/news/sport/285993-u-lvovi-provedut-pershyi-v-ukraini-chempionat-zi-skladannia-pazliv-na-shvydkist"
              rel="noopener noreferrer"
              target="_blank"
            >
              Медіа про Нас
            </a>
          </h2>
        </div>
      </section>

      <div className="centered" style={{ margin: `0 auto` }}>
        <img src={p11} alt="puzzle" />
      </div>

      <section>
        <div className="container" style={{ padding: `40px 0` }}>
          <h3
            className="styled_red uppercase res_title"
            style={{ color: `#FDD935` }}
          >
            ЕТАПИ 2024:
          </h3>
          <div style={{ margin: `30px auto` }}>
          <h2>1-5 квітня - школи садки</h2>
          <h2>6 квітня - індивідуальні</h2>
          <h2>13 квітня - фінал</h2>

          </div>
        </div>
      </section>

      <section>
        {/* <div style={{ width: `100%`, margin: `0 auto`, maxWidth: `480px` }}>
          <img src={p1} alt="puzzle" />
        </div> */}
        <div className="container" style={{ padding: `40px 0` }}>
          <h3 className="uppercase res_title" style={{ color: `#00ABE1` }}>
            КАТЕГОРІЇ:
          </h3>

          <div style={{ margin: `30px auto` }}>
            <h2>3-5 років - 30 елементів</h2>
            <h2>6-8 років - 60 елементів</h2>
            <h2>9-11 років - 100 елементів</h2>
            <h2>Від 14 років -  500 елементів</h2>
            <h4 style={{ textAlign: `center` }}>
              * всі пазли з відповідних серій https://dodotoys.com.ua/
            </h4>
          </div>
        </div>
      </section>
      <div style={{ width: `100%`, margin: `0 auto`, maxWidth: `480px` }}>
        <img src={p2} alt="puzzle" />
      </div>
      <Contact />
      <Sponsors />
    </article>
  </Layout>
)

export default IndexPage
