import React from "react"
// import { StaticImage } from "gatsby-plugin-image"
import dodo from "../../images/logo_Openlab1.png"
import boyar from "../../images/boyarlogo.png"
import lviv from "../../images/lviv2.png"



const Featured = () => {
  return (
    <section className="featured">
      <div className="container card_grid">
        <div className="card">
          <img src={dodo} alt="openlab" />
        </div>
        <div className="card" style={{ marginBottom: `20px` }}>
          <img src={lviv} alt="lviv" />
        </div>
        <div className="card">
          <img src={boyar} alt="boyar" />
        </div>
      </div>
      <div className="container" style={{ marginBottom: `36px` }}>
        <h2 className="res_title_m">ЗАПРОШУЄМО НА МІЖНАРОДНИЙ</h2>
        <h2 className="styled_title res_title">PUZZLE CHAMPIONSHIP 2024</h2>
        <h2>4-ИЙ ЧЕМПІОНАТ З ШВИДКІСНОГО СКЛАДАННЯ ПАЗЛІВ 2024</h2>
      </div>
    </section>
  )
}

export default Featured
