import React from 'react'
import ContactGorm from '../../ui/ContactGorm'

const Contact = () => {
    return (
        <section>
           <div className="container">
                <ContactGorm />
           </div>
        </section>
    )
}

export default Contact
