import React, { Component } from "react"
import TextField from "@material-ui/core/TextField"
import MenuItem from "@material-ui/core/MenuItem"
import InputMask from "react-input-mask"
import Button from "@material-ui/core/Button"
import axios from "axios"
import { CircularProgress } from "@material-ui/core"


import SuccesPayment from "../ui/SuccesPayment"
// import liqpaylogo from "../images/liq-logo.png"


const API_PATH = "https://uapuzzle.com/form/index.php"
export default class ContactGorm extends Component {
  state = {
    textmask: "(  )    -    ",
    isLoaded: false,
    name: "",
    phone: "",
    url: false,
    // category: "3-5 років",
    // location: "Boyar",
    category: "",
    desc: false,
    location: "",
    categories: [],
    city: "",
    cities: [
      "Львів", "Київ", "Ужгород","Рівне"
      
    ],
    locationsByCity:[],
    locations: [],
    // categories: [
    //   {
    //     value: "3-5 років",
    //     label: "3-5 років",
    //   },
    //   {
    //     value: "6-8 років",
    //     label: "6-8 років",
    //   },
    //   {
    //     value: "9-11",
    //     label: "9-11",
    //   },
    //   {
    //     value: "14+ парний",
    //     label: "14+ парний",
    //   },
    // ],
    // locations: [
    //   {
    //     value: "Boyar",
    //     label: "Boyar",
    //   },
    //   {
    //     value: "Smartcup",
    //     label: "Smartcup",
    //   },
    // ],

    formIsSent: false,
    oplataOnlineIsShown: false,
  }

  componentDidMount() {
    this.getData()
  }

  getData = async () => {
    await axios({
      method: "post",
      url: `https://uapuzzle.com/form/info/`,
      headers: { "content-type": "application/json" },
    })
      .then(result => {
        if (result.data.categories) {
          console.log(result.data);
          // console.log(Object.values(result.data.categories), "REFORMED");
          let categories = Object.values(result.data.categories);

         let optionscategories = [
            {
              title: categories[0].title,
              locations: categories[0].locations,
            },
            {
              title: categories[1].title,
              locations: categories[1].locations,
            },
            {
              title: categories[2].title,
              locations: categories[2].locations,
            },
            {
              title: categories[3].title,
              locations: categories[3].locations,
            },
            {
              title: categories[4].title,
              locations: categories[4].locations,
            },
          ]

          this.setState({
            isLoaded: true,
            formIsSent: false,
            categories: optionscategories,
            category: optionscategories[0].title,
            locationsByCity: result.data.locationsByCity
          })
        }
      })
      .catch(error => this.setState({ error: error.message, isLoaded: false }))
  }


  postData = async () => {
    let formData = {
      name: this.state.name,
      phone: this.state.phone,
      category: this.state.category,
      location: this.state.location,
      city: this.state.city,
    }

   
    await axios({
      method: "post",
      url: `${API_PATH}`,
      headers: { "content-type": "application/json" },
      data: formData,
    })
      .then(result => {
         if (result.data.url) {
           this.setState({
             oplataOnlineIsShown: true,
             formIsSent: true,
             url: result.data.url,
           })
         } 
        // console.log(result.data)
        if (result.data.status === 1){
            this.setState({
            oplataOnlineIsShown: true,
            desc: result.data.desc ? result.data.desc : false,
            formIsSent: true,
            url: result.data.img,
          })
        }
        
        else {
          // console.log("no pay online")
          this.setState({
            formIsSent: true,
            url: undefined,
          })
        }
      })
      .catch(error => this.setState({ error: error.message }))
  }

  handleFormSubmit = e => {


    e.preventDefault()
    let validData = {
      phoneisValid: this.state.phone.length < 13 ? false : true,
      nameisValid: this.state.name.length >= 6 ? true : false,
     // cityValid: this.state.city.length > 1 ? true : false,
     // locationValid: this.state.location.length > 1 ? true : false,
      
    }
    if (Object.values(validData).includes(false)) {
      this.setState({
        nameerror: this.state.name.length < 6 ? true : false,
        phoneerror: this.state.phone.length < 13 ? true : false,
      })
      return
    } else {
      
      this.postData()
    }
  }

  setCategory = e => {
    this.setState({
      category: e.target.value,
     // locations: this.checkloactions(e.target.value),
     // location: this.checkloaction(e.target.value),
    })
  }

  setCity = e => {

    
    this.setState({
      city: e.target.value,
      locations: this.state.locationsByCity[e.target.value],
      
    })
  }
  handleName = e => {
    this.setState({
      // name: e.target.value.replace(/\b\w+/g, ""),
      name: e.target.value,
      nameerror: false,
    })
  }

  // componentDidUpdate() {
  //   console.log(this.state)
  // }

  checkloactions = title => {
    let result = this.state.categories.filter(
      category => category.title === title
    )
    return result[0].locations
  }

  checkloaction = title => {
    let result = this.state.categories.filter(
      category => category.title === title
    )
    return result[0].locations[0]
  }

  render() {

    
     
     return (
    
      <div id="section-1"
        style={{
          width: `100%`,
          padding: `20px 10px`,
          borderRadius: `10px`,
          background: `white`,
        }}
      >
         
         <h2 className="res_title">РЕЄСТРАЦІЯ ЗАВЕРШЕНА</h2>

      <h2>
       Чемпіонат із складання пазлів вже відбувається. Слідкуйте за нами у соцмережах
      </h2> 
      <div>
        <h3>Результати відбіркого туру будуть на сторінці незабаром</h3>

        <ul>
         
        </ul>
      </div>
      </div>
      )
      

      /*
      return (
        <div
        id="section-1"
        style={{
          width: `100%`,
          padding: `20px 10px`,
          borderRadius: `10px`,
          background: `white`,
        }}
      >
      <h2 className="res_title">РЕЄСТРАЦІЯ</h2>
     
        {!this.state.formIsSent ? (
          <React.Fragment>
            {this.state.isLoaded ? (
              <form action="#" id="contact_form" autoComplete="off">
                <div className="row_wrapper">
                  <TextField
                    id="standard-required"
                    label="Прізвище Ім’я"
                    className="form_input"
                    value={this.state.name}
                    onChange={e => this.handleName(e)}
                  />
                  {this.state.nameerror ? (
                    <p style={{ color: `red`, padding: `6px` }}>
                      Введіть прізвище та ім’я
                    </p>
                  ) : null}
                </div>
                <div className="row_wrapper">
                  <label htmlFor="phone">Номер мобільного</label>
                  <InputMask
                    mask="+389999999999"
                    name="phone"
                    placeholder="+38 000 000 00 00"
                    className="u-full-width"
                    maskChar={null}
                    value={this.state.phone}
                    onChange={e =>
                      this.setState({
                        phone: e.target.value,
                        phoneerror: false,
                      })
                    }
                  />
                  {this.state.phoneerror ? (
                    <p style={{ color: `red`, padding: `6px` }}>
                      введіть номер мобільного
                    </p>
                  ) : null}
                </div>
                <div className="row_wrapper">
                  <TextField
                    id="standard-select-currency"
                    select
                    label="КАТЕГОРІЯ"
                    value={this.state.category}
                    className="form_input"
                    onChange={e => this.setCategory(e)}
                    helperText="Оберіть категорію"
                  >
                    {this.state.categories.map((option, index) => (
                      <MenuItem key={index} value={option.title}>
                        {option.title}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>

                <div className="row_wrapper">
                  <TextField
                    id="standard-select-currency"
                    select
                    label="МІСТО"
                    value={this.state.city}
                    onChange={e => this.setCity(e)}
                    className="form_input"
                    helperText="Оберіть місто"
                  >
                    {this.state.cities.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>




                <div className="row_wrapper">
                  <TextField
                    id="standard-select-currency"
                    select
                    label="ЛОКАЦІЯ"
                    value={this.state.location}
                    onChange={e => this.setState({ location: e.target.value })}
                    className="form_input"
                    helperText="Оберіть локацію"
                  >
                    {this.state.locations.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="centered">Ваші дані захищено.</div>
                <div className="centered">
                 

                  <Button  onClick={e => this.handleFormSubmit(e)}>
                  Зареєструватись
                  </Button>
                </div>
              </form>
            ) : (
              <div className="centered" style={{ minHeight: `260px` }}>
                <CircularProgress />
              </div>
            )}
          </React.Fragment>
        ) : (
          <div
            style={{
              width: `100%`,
              minHeight: `300px`,
              paddingTop: `30px`,
            }}
          >
            {this.state.formIsSent && (
              <React.Fragment>
                <h2 style={{ color: `green` }}>Реєстрація успішна.</h2>
                <SuccesPayment />
              </React.Fragment>
            )}
            {this.state.oplataOnlineIsShown && (
              <div style={{ margin: `0 auto`, width: `70%`, padding: `20px` }}>
               
                <div className="centered">
                  {this.state.url ? (
                    // <a
                    //   href={this.state.url}
                    //   rel="noopener noreferrer"
                    //   target="_blank"
                    //   style={{ textDecoration: `none` }}
                    // >
                    //   <Button variant="outlined">Оплатіть з допомогою QR-код та додатку Вашого банку.</Button>
                    // </a>
                        <div>
                          
                            {
                              this.state.desc &&
                              <h4 style={{textAlign: `center`}} dangerouslySetInnerHTML={{ __html: `${this.state.desc}` }}></h4>
                            }
                          <div style={{margin :`20px auto`, width: `100%`, maxWidth: `320px`}}>
                            <img src={this.state.url} alt="icon" />
                          </div>
                        </div>
                  ) : null}
                </div>
              </div>
            )}
          </div>
        )}
      }
      </div>
    )*/
  }
}
